export const humanizeKey = (input: string): string => {
  return input
    .replace(/[-_]/g, ' ')
    .split(' ')
    .map(el => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ')
}

export const validatePassword = (
  value: string,
  setErrorMsgPassword: (message: string) => void,
  requiredMessage = 'Need a password',
  errorMessage = 'Password must contain letters, numbers and be at least 8 characters long',
) => {
  if (!value) {
    setErrorMsgPassword(requiredMessage)
    return false
  }

  if (!value.match(/(?=.*\d)(?=.*[a-zA-Z]).{8,}/)) {
    setErrorMsgPassword(errorMessage)
    return false
  }

  setErrorMsgPassword('')

  return true
}

export const validateEmail = (value: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

// this will be a subdomain
export const validateShopName = (value: string) => {
  return /^[-A-Z0-9 ]{5,}$/i.test(value)
}

export const isLocal = () => !isStaging() && !isProduction()
export const isStaging = () => process.env.NEXT_PUBLIC_ENV === 'staging'
export const isProduction = () => process.env.NEXT_PUBLIC_ENV === 'production'
export const isBrowser = () => typeof window !== 'undefined'
