import type { AppProps } from 'next/app'
import Head from 'next/head'

import { Layout } from '@/components/layout/Layout'
import '@/styles/globals.css'
import { isStaging, isProduction, isBrowser } from '@/lib/helper'
import { ErrorReporter } from '@/lib/ErrorReporter'

const staging = isStaging()
const production = isProduction()
const tagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

ErrorReporter.initFor(isBrowser() ? 'browser' : 'server')

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta name='author' content='Branchbob GmbH' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel='shortcut icon' href='images/favicon.svg' />
        <title>AI-Powered Online Store Builder - branchbob</title>
        <meta
          name='description'
          content='Build your online store in seconds with our AI-powered store builder. Get a professional website and start selling online today. No coding skills required!'
        />

        {(staging || production) && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
              }}
            ></script>
            <script
              async
              src='https://r.wdfl.co/rw.js'
              data-rewardful='df9af1'
            ></script>
          </>
        )}
        {production && tagManagerId && (
          // eslint-disable-next-line @next/next/next-script-for-ga
          <script
            id='gtm-script'
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${tagManagerId}');
                        `,
            }}
          />
        )}
      </Head>
      <div className='nk-app-root bg-darker'>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </div>
    </>
  )
}
