import Link from 'next/link'
import { FC } from 'react'

export const Header: FC = () => {
  return (
    <div className='mx-auto w-full max-w-[1000px] flex justify-between'>
      <div className='left md:pl-[20px]'>
        <Link
          href='/'
          className='inline-block mb-5 md:mb-10  w-[140px]  md:w-[240px]'
        >
          <img
            src='/images/Logo.svg'
            alt='Branchbob'
            className='h-auto w-full object-contain'
          />
        </Link>
      </div>

      <div className='flex md:justify-end'>
        <Link href='/functions' className='mb-5 md:mb-10 pr-[20px]'>
          Functions
        </Link>
        <Link href='/pricing' className='mb-5 md:mb-10 md:pr-[20px]'>
          Pricing
        </Link>
      </div>
    </div>
  )
}
