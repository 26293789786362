import Bugsnag, { NotifiableError } from '@bugsnag/js'

import { isLocal } from './helper'

type AppTypes = 'server' | 'monitor' | 'worker' | 'browser'

export class ErrorReporter {
  static initFor(appType: AppTypes) {
    if (isLocal()) return

    Bugsnag.start({
      appType: appType,
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
      appVersion: process.env.HEROKU_RELEASE_VERSION,
      releaseStage: process.env.NEXT_PUBLIC_ENV,
    })
  }

  static notify(e: unknown) {
    if (isLocal()) {
      console.error(e)
      return
    }

    console.error(e)
    Bugsnag.notify(e as NotifiableError)
  }

  static setContext(context: string) {
    if (isLocal()) return

    Bugsnag.setContext(context)
  }
}
