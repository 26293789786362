import { FC, PropsWithChildren } from 'react'

import { Footer } from './Footer'
import { Header } from './Header'

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='bg-accent-dark text-white'>
      <div className='relative z-0 min-h-screen overflow-hidden px-5 pb-9 pt-5 md:pb-36 md:pt-[60px] lg:px-10'>
        <div className='mx-auto w-full'>
          <Header />

          {children}

          <Footer />
        </div>

        <img
          src='/images/background.svg'
          alt='background'
          className='pointer-events-none absolute left-1/2 top-0 -z-10 hidden -translate-x-1/2 md:block'
        />
        <img
          src='/images/mobile-shape.svg'
          alt='background'
          className='pointer-events-none absolute right-0 top-0 -z-10 block md:hidden'
        />
      </div>
    </div>
  )
}
