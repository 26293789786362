import { FC } from 'react'
import Link from 'next/link'

export const Footer: FC = () => {
  return (
    <footer className='mx-auto w-full max-w-[1000px] md:absolute bottom-5 mt-5 left-0 right-0'>
      <Link href='#' className='mx-auto mb-5 block w-fit sm:mx-0 md:mb-[30px]'>
        <img
          src='/images/Logo.svg'
          alt='Branchbob'
          className='w-[100px] object-contain'
        />
      </Link>
      <div className='flex flex-col items-center justify-between gap-5 sm:flex-row md:gap-10'>
        <p className='font-grotesk text-[15px] font-semibold text-white'>
          &copy; 2023 branchbob
        </p>
        <ul className='flex flex-col items-center gap-5 sm:flex-row md:gap-[20px]'>
          <li>
            <Link
              href='/affiliate'
              className='font-grotesk text-lg text-accent-blue transition-all hover:text-blue-700 hover:underline'
            >
              Affiliate
            </Link>
          </li>
          <li>
            <Link
              href='/terms'
              className='font-grotesk text-lg text-accent-blue transition-all hover:text-blue-700 hover:underline'
            >
              Terms
            </Link>
          </li>
          <li>
            <Link
              href='/privacy'
              className='font-grotesk text-lg text-accent-blue transition-all hover:text-blue-700 hover:underline'
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link
              href='/imprint'
              className='font-grotesk text-lg text-accent-blue transition-all hover:text-blue-700 hover:underline'
            >
              Imprint
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
